import { useState, useMemo, useCallback, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import { DriverListContext } from 'context/drivers/DriverListContext'

import CreateOrEditDriverDialog from './CreateOrEditDriverDialog'
import DeleteDriverDialog from './DeleteDriverDialog'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'
import { replaceHighlights } from 'global/helpers/highlights'

// Types
import { DriverData } from './DriverManager.types'
import { Role } from 'context/authentication/Role.types'

interface DriverTableProps {
  drivers: DriverData[]
  onChange?: (updatedDriver: DriverData, action: string) => void
}

const DriverTable = ({ drivers, onChange }: DriverTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeDriver, setActiveDriver] = useState<DriverData>()
  const [createOrEditDriverDialogOpen, setCreateOrEditDriverDialogOpen] = useState(false)
  const [deleteDriverDialogOpen, setDeleteDriverDialogOpen] = useState(false)
  const [driverListState] = useContext(DriverListContext)

  const handleEditClick = useCallback((row: DriverData) => {
    setActiveDriver(row)
    setCreateOrEditDriverDialogOpen(true)
    analyticsEvent('polestar_cs_edit_driver_button_clicked')
  }, [])

  const handleDeleteClick = useCallback((row: DriverData) => {
    setActiveDriver(row)
    setDeleteDriverDialogOpen(true)
    analyticsEvent('polestar_cs_delete_driver_button_clicked')
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: t('screens.cs.driver_manager.card.columns.name'),
        getValue: (row: DriverData) => {
          return <KNTypography variant='p3' color="text.main"> {replaceHighlights(row.name, driverListState.filters.keywords)}</KNTypography>
        },
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'contactNumber',
        label: t('screens.cs.driver_manager.card.columns.phone'),
        getValue: (row: DriverData) =>  {
          return <KNTypography variant='p3' color="text.main"> {replaceHighlights(row.contactNumber, driverListState.filters.keywords) ?? '—'}</KNTypography>
        },
        sx: { width: '18rem' },
      },
      {
        name: 'email',
        label: t('screens.cs.driver_manager.card.columns.email'),
        getValue: (row: DriverData) => {
          return <KNTypography variant='p3' color="text.main"> {replaceHighlights(row.email, driverListState.filters.keywords) ?? '—'}</KNTypography>
        },
        sx: { width: { xs: '18rem', xxl: '24rem' } },
      },
    ],
    [driverListState.filters.keywords]
  )

  const actions = useMemo(
    () => [
      {
        name: 'edit',
        label: t('screens.cs.driver_manager.card.actions.edit_short'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      },
      {
        name: 'delete',
        label: t('screens.cs.driver_manager.card.actions.delete'),
        icon: <DeleteIcon />,
        onClick: handleDeleteClick,
      },
    ],
    []
  )

  const handleCreateOrEditDialogAction = useCallback((updatedDriver: DriverData, action: string): void => {
    setCreateOrEditDriverDialogOpen(false)
    onChange?.(updatedDriver, action)
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditDriverDialogOpen(false)
  }, [])

  const handleDeleteDialogAction = useCallback((updatedDriver: DriverData, action: string): void => {
    setDeleteDriverDialogOpen(false)
    onChange?.(updatedDriver, action)
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteDriverDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={hasRole(Role.Editor) ? actions : []}
        data={drivers}
        sx={{
          marginX: -2,
        }}
      />
      {activeDriver && hasRole(Role.Editor) && (
        <>
          <CreateOrEditDriverDialog
            payload={{
              driver: activeDriver,
            }}
            open={createOrEditDriverDialogOpen}
            onAction={handleCreateOrEditDialogAction}
            onClose={handleCreateOrEditDialogClose}
          />
          <DeleteDriverDialog
            payload={{
              driver: activeDriver,
            }}
            open={deleteDriverDialogOpen}
            onAction={handleDeleteDialogAction}
            onClose={handleDeleteDialogClose}
          />
        </>
      )}
    </>
  )
}

export default DriverTable
