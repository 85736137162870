import { ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { KNAdvancedDropdownProps } from './types'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

const KNAdvancedDropdown = ({
  label,
  placeholder,
  value,
  options,
  onChange,
  onDelete,
  multiple,
  size,
  disabled,
  sx,
  error,
  helperText,
}: KNAdvancedDropdownProps): ReactElement => (
  <FormControl
    size={size}
    fullWidth
    sx={sx}
    error={error}
    disabled={disabled}
  >
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      data-test="dropdown"
      displayEmpty
      multiple={multiple}
      renderValue={(selected: string | string[]): ReactElement => {
        if (selected.length === 0 && placeholder) {
          return (
            <KNTypography variant='p3'>{placeholder}</KNTypography>
          )
        }
        const selectedOptions = options.filter((option) => selected.includes(option.value))
        if (!multiple && selectedOptions.length === 1) {
          return (
            <>
              {selectedOptions[0].label}
            </>
          )
        }
        return (
          <>
            {selectedOptions.map((option) => (
              <Chip
                key={option.value}
                variant="outlined"
                size="small"
                label={option.label}
                onDelete={onDelete ? (event) => {
                  onDelete(option.value)
                } : undefined}
                // pevents opening the dropdown list when clicking on "delete" element
                onMouseDown={onDelete ? (event) => {
                  if ((event.target instanceof HTMLElement || event.target instanceof SVGElement) && !event.target.classList.contains('MuiChip-label')) {
                    event.preventDefault()
                    event.stopPropagation()
                  }
                } : undefined}
              />
            ))}
          </>
        )
      }}
      value={value ?? (multiple ? [] : '')}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.icon}
          <ListItemText disableTypography primary={option.label} />
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)

export default KNAdvancedDropdown
